import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function FeaturedStory({key, index, ...props}) {

  let reverse = false
  const section = props.featuredStory

  const textCard = {
    "heading": {
      "type": "h2",
      "text": section.story.title
    },
    "content": section.story.excerpt,
    "buttons": [
      {
        "button": {
          "button": {
            "button": {
              "title": "Read story",
              "url": section.story.uri,
              "target": ""
            }
          },
          "type": "button"
        }
      }
    ]
  }

  const alignImage = section?.alignImage

  if(alignImage === 'right'){
    reverse = true
  }
  
  return (
    <section id={section.fieldGroupName + index}  className={`my-20 md:my-36`}>
      <div className="container">
        <div className="grid grid-cols-12 gap-y-20 lg:gap-y-0 items-center relative grid-flow-row-dense">
          <FadeInWhenVisible className={`relative col-span-12 lg:col-span-6 ${reverse && 'lg:col-start-7'}`}>
            <div className="w-[90%] ml-auto block">
              <div className="aspect-w-1 aspect-h-1">
                <Image data={section.story.featuredImage?.node} className={`!absolute w-full h-full`} />
              </div>
            </div>
            {(section.story.partnerStoryItem?.partnerStoryItem?.stat?.heading && section.story.partnerStoryItem?.partnerStoryItem?.stat?.content) && (
              <div className="bg-sand py-16 px-8 inline-block max-w-[15rem] text-center absolute transform -translate-y-3/4">
                <div className="text-h1">{section.story.partnerStoryItem?.partnerStoryItem?.stat.heading}</div>
                <div className="text-h6">{section.story.partnerStoryItem?.partnerStoryItem?.stat.content}</div>
              </div>
            )}
          </FadeInWhenVisible>
          <div className={`col-span-12 lg:col-span-5  relative ${reverse ? 'lg:col-start-1' : 'lg:col-start-8'}`}>
            <TextCard className="" {...textCard} />
          </div>
        </div>
      </div>
    </section>
  )
}
